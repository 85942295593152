export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  clientId: +localStorage.getItem('idEmpresa'),
  // apiLogin: 'https://api.aplicativo.farmcont.com.br',       // MAIN

  // apiLogin: 'http://192.168.0.27:6060',                    // TEST ENV - INTERNO
  // apiLogin: 'http://187.39.52.234:6060',                   // TEST ENV - EXTERNO

  apiLogin: 'http://ambtestesexterno.ddns.net:6060',  // TEST ENV - EXTERNO

   requireHttps: false,
 // requireHttps: true,
 // apiLogin: 'http://localhost:6060'
 //  apiLogin: 'http://192.168.0.27:6060' // teste - local
  // apiLogin: 'http://187.39.52.234:6060', // teste - externo
  // apiLogin: "http://18.229.252.194:6060"

};
